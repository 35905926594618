import React from 'react';
import { Grid, Card, Typography, Button } from '@material-ui/core';

function PersonalProduct({ title, logo, role, description, link, appImage }) {
  return (
    <div>
      <div className="project_contanier">
        <Card>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <div className="project">
                <div className="project_left">
                  <div className="project_platform">
                    <img className="brand_logo" src={logo} alt={title} />
                    <p>{role}</p>
                  </div>
                  <div>
                    <Typography
                      id="project__heading"
                      gutterBottom
                      variant="h5"
                      component="h2"
                    >
                      {title}
                    </Typography>

                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {description}
                    </Typography>
                  </div>
                  <div>
                    {link && (
                      <Button
                        href={link}
                        target="_blank"
                        id="button"
                        variant="contained"
                        size="small"
                        color="primary"
                      >
                        Visit Web Page
                      </Button>
                    )}
                  </div>
                </div>
                <div className="project_right">
                  <img alt={`${title} screenshot`} src={appImage || logo} />
                </div>
              </div>
            </Grid>
          </Grid>
        </Card>
      </div>
    </div>
  );
}

export default PersonalProduct; 