import React from "react";
import logo from "./images/Logo.png";
import { Check } from "@material-ui/icons";

import myPhoto from "./images/myphoto.jpg";
import EcSolution from "./images/logo-transparent.png";

import code from "./images/code.svg";

import "./App.css";
import ProjectDatabase from "./components/ProjectData";
import base from "./base";
import { useEffect } from "react";
import { useState } from "react";

import SportsSoccerOutlinedIcon from "@material-ui/icons/SportsSoccerOutlined";
import CodeOutlinedIcon from "@material-ui/icons/CodeOutlined";
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined";
import ArrowDownwardOutlinedIcon from "@material-ui/icons/ArrowDownwardOutlined";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import InstagramIcon from "@material-ui/icons/Instagram";
import MailIcon from "@material-ui/icons/Mail";

import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import PersonalProduct from './components/PersonalProduct';
import farabuilderLogo from './images/farabiulder_logo.png';
import farabiulderApp from './images/farabiulder-app.png';
import bubbvishLogo from './images/Bubvisch-Logo.png';
import bubvischApp from './images/bubvisch-app.png';

function App() {
  const [project, setProject] = useState([]);

  useEffect(() => {
    base("Project")
      .select({
        // Selecting the first 3 records in Grid view:
        // filterByFormula:`{${Shopify}} = ${Platforms}`,
        view: "Grid view",
      })
      .eachPage((records, fetchNextPage) => {
        setProject(records);
        fetchNextPage();
      });
  },[]);

  return (
    <div className="app">
      <header className="app-header">
        <img src={logo} className="app-logo" alt="logo" />
        <div className="header__right">
          <a
            className="farabiulder_cta"
            href="https://farabiulder.com/"
            target="_blank"
          >
            <u>Farabiulder</u> 🚀
          </a>
          <a
            className="cta_button"
            href="mailto:iamefeenes@gmail.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            Say Hello
          </a>
        </div>
      </header>
      <div className="home">
        <div className="home_container">
          <div className="home_container_texts">
            <h1>Sports Scientist, Full-Stack Developer, Maker</h1>
            <p>I have a passion for solving problems with cutting-edge tech-stack.</p>
          </div>
          <img className="my__photo" src={myPhoto} alt="enes-efePhoto" />
        </div>
      </div>

      <div className="home__codePhoto">
        <img src={code} alt="code_photo" />
      </div>
      <div className="home__second">
        <div className="home__second__texts">
          <h1>Hello I'm Enes. Nice to meet you.</h1>
          <h2>
            {" "}
            I started problem solving with curiosity. At first, curiosity made
            me feel lost alone in such a large sea. Then this curiosity got more
            and more, and now I have been solving the problems around me using
            technology.{" "}
          </h2>
        </div>
      </div>
      <div className="about_me">
        <div className="jobs">
          <div className="about_me_jobs">
            <SportsSoccerOutlinedIcon fontSize="large" />
            <h1>Sports Scientist</h1>
            <p className="jobs__p">
              {" "}
              I love working on <b className="bold__color">
                new technologies
              </b>{" "}
              in the field of sports science.
            </p>
            <p className="jobs__p__head">Topics I'm interested in:</p>
            <p>Sport Analyze, Performance, Flexibility</p>
            <p className="jobs__p__head">Diploma and Certificates:</p>
            <p>
              Sports Management (B.Sc.)
              <br />
              Sports Coaching (Double Major)
              <br />
              Pedagogical Formation (Certificate)
              <br />
              TFF. C Football Certificate
              <br />
              Pilates Reformer Level - 1<br />
              Iaaf Kids' Athletics Coach (Certificate)
              <br /> Taekwondo (Black Belts 1. Dan) <br /> Kick Boxing (Brown
              Belts) <br />
              Muay Thai (9. Khan)
            </p>
          </div>
          <div className="about_me_jobs">
            <CodeOutlinedIcon fontSize="large" />
            <h1>Full-Stack Developer</h1>
            <p className="jobs__p">
              {" "}
              I love coding and releasing new products. Also, thanks to{" "}
              <b className="bold__color">curiosity</b>, I can realize many
              projects.
            </p>
            <p className="jobs__p__head">Languages I speak:</p>
            <p>Html, Css, Javascript, React, Svelte,</p>
            <p className="jobs__p__head__tools">Tools:</p>
            <p>
              Bubble.io
              <br />
              Shopify
              <br />
              Shopware
              <br />
              Wordpress
              <br />
              Adalo
              <br />
              Appygver
              <br />
              Firebase
              <br />
              Material Ui
              <br />
              Bootstrap
              <br />
              Photoshop
              <br />
              Github
              <br />
              Terminal
            </p>
          </div>
          <div className="about_me_jobs">
            <EmojiObjectsOutlinedIcon fontSize="large" />
            <h1>Maker</h1>
            <p className="jobs__p">
              {" "}
              <b className="bold__color">
                {" "}
                Finding solutions to problems in some way
              </b>{" "}
              is at the center of my life.
            </p>
            <p className="jobs__p__head">Sectors I'm Interested in:</p>
            <p>Sports, Saas, Marketplace, E-commerce</p>
            <p className="jobs__p__head">Projects:</p>
            <ArrowDownwardOutlinedIcon id="below__icon" />
          </div>
        </div>
      </div>
      <div className="my__projects">
        <h1 className="partnership__heading">
          Bussiness Partnership
          <div className="project_platform">
            <Check />
            <p>Open to New Opportunities</p>
          </div>
        </h1>

        <div className="projects">
          <div>
            <div className="project_contanier">
              <Card>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <div className="project">
                      <div className="project_left">
                        <div className="project_platform">
                          <img className="brand_logo" src={logo}></img>
                          <p>Co-Founder</p>
                        </div>
                        <div>
                          <Typography
                            id="project__heading"
                            gutterBottom
                            variant="h5"
                            component="h2"
                          >
                            Ec-Solution Software Agency
                          </Typography>

                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                          >
                            We are developing many web and mobile projects with
                            my partner under the name of ec-solution.net.
                            Generally, our customers are located in Germany. And
                            we are very happy that it is an initiative where we
                            offer global solutions together with local solutions
                            to our customers and we evaluate the incoming
                            projects with pleasure. <br />
                            Detailed tasks:
                            <br />
                            <ul>
                              <li>
                                Drawing up the WEB page concept according to the
                                customer's requests
                                <ul>
                                  <li>Gather requirements</li>
                                  <li>
                                    Discussion of solutions about which platform
                                    to use
                                  </li>
                                </ul>
                              </li>
                              <li>
                                Cooperation in the desing
                                <ul>
                                  <li>
                                    Drawing up the design concept with the
                                    client.
                                  </li>
                                  <li>Making the wireframe of the website</li>
                                  <li>Creation of the overall design</li>
                                </ul>
                              </li>
                              <li>
                                Cooperation in the development
                                <ul>
                                  <li>
                                    Choosing the most suitable CMS or e-commerce
                                    infrastructure for the customer.
                                  </li>
                                  <li>
                                    Transfer of the design to the relevant
                                    platform as requested
                                  </li>
                                  <li>
                                    Development of the front-end of the site
                                  </li>
                                  <li>
                                    PayPal or other payment platforms
                                    configurations
                                  </li>
                                </ul>
                              </li>
                              <li>
                                Technologies used (among others)
                                <ul>
                                  <li>
                                    Wordpress, Shopify, Shopware, Bubble.io,
                                  </li>
                                  <li>Figma, Photoshop, Photopea</li>
                                  <li>Netlify, Github</li>
                                  <li>Git, Jira, Scrum</li>
                                </ul>
                              </li>
                            </ul>
                          </Typography>
                        </div>
                        <div>
                          {/* whenClicked is a property not an event, per se. 
                             <Button href="https://ec-solution.net/" target="_blank" id="button" variant="contained" size="small" color="primary">
                                Visit Web Page
                              </Button>
                              */}
                        </div>
                      </div>
                      <div className="project_right">
                        <img alt="ec-solution.net" src={EcSolution} />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <div className="my__projects">
        <h1 className="personal__products__heading">
          Personal Products
         
        </h1>

        <div className="projects">
          <PersonalProduct 
            title="Farabiulder"
            role="Founder"
            description="Farabiulder is a Chrome extension for Bubble.io that lets you easily create and share component libraries. With Farabiulder, you can copy and paste any group, element, or page, create your own component library, and effortlessly share your designs with yourself, your team, clients or the world - all with just a click. Say goodbye to the hassle of searching through endless files and complex export steps."
            link="https://farabiulder.com/"
            logo={farabuilderLogo}
            appImage={farabiulderApp}
          />
          
          <PersonalProduct 
            title="Bubbvish"
            role="Founder"
            description="Bubbvish is a Chrome extension that transforms your Bubble.io database development experience by providing instant visual representation of your database schema and relationships. It helps developers and teams better understand and streamline their database structure without storing any of your data. With features like real-time updates and secure local processing, Bubbvish makes database development more efficient and collaborative."
            link="https://bubvisch.com/"
            logo={bubbvishLogo}
            appImage={bubvischApp}
          />
        </div>
      </div>
      <div className="my__projects">
        <h1>My Recent Work</h1>

        <div className="projects">
          {project.map((e) => (
            <ProjectDatabase key={e.id} project={e}></ProjectDatabase>
          ))}
        </div>
      </div>
      <div className="contact">
        <h1>Interested in collaborating or investing?</h1>
        <p>
          I'm always open to discussing web development, or partnership
          opportunities.
        </p>

        <a
          href="mailto:iamefeenes@gmail.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          Start a conversation
        </a>
      </div>
      <footer className="footer">
        <div className="footer__img">
          <img src={logo} alt="iamenes.com__Logo" />
        </div>
        <p>Lifetime learner</p>
        <div className="footer__social">
          <a
            href="https://www.linkedin.com/in/enes-efe/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="footer_social__Button">
              <LinkedInIcon />
            </div>
          </a>
          <a
            href="https://github.com/enesefes"
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="footer_social__Button">
              <GitHubIcon />
            </div>
          </a>
          <a
            href="https://www.instagram.com/enes_efes/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="footer_social__Button">
              <InstagramIcon />
            </div>
          </a>
          <a
            href="mailto:iamefeenes@gmail.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            <div className="footer_social__Button">
              <MailIcon />
            </div>
          </a>
        </div>
      </footer>
    </div>
  );
}

export default App;
